import React, { useEffect, useState } from "react";

function useLoading(isLoading = false, text) {
  const [spinner, setSpinner] = useState(text);

  useEffect(() => {
    if (isLoading) {
      setSpinner(
        <React.Fragment>
          <span
            class="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          ></span>
          Please wait ...
        </React.Fragment>
      );
    } else {
      setSpinner(text);
    }
  }, [isLoading, text]);

  return spinner;
}

export default useLoading;
