import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminHeader from "../components/headers/AdminHeader";
import React from "react";

export default function AdminLayout({ children }) {
  return (
    <>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <AdminHeader />
        </header>
        {/* end header */}
        <div className="vertical-menu">
          <AdminSidebar />
        </div>
        {/* end  */}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">{children}</div>
          </div>
          {/* end page content */}
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">2023 © Umbrella.</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/* end footer */}
        </div>
      </div>
      {/*  end layout */}
      {/* <!-- Overlay--> */}
      <div className="menu-overlay"></div>
    </>
  );
}
