import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useLoading from "../../hooks/useLoading";

export default function LoginForm({
  hide = [],
  onLoginSubmit = () => {},
  isLoading = false,
  message = "",
  messageType = "",
}) {
  const { register, handleSubmit, formState: errors } = useForm();
  const buttonText = useLoading(isLoading, "Login");

  return (
    <form
      action="#"
      className="needs-validation p-2"
      onSubmit={handleSubmit(onLoginSubmit)}
    >
      <div className="form-group">
        <label htmlFor="login">Login</label>
        <input
          className="form-control"
          type="text"
          id="login"
          placeholder="Login Id"
          {...register("login", { required: true })}
        />
        {errors.login && (
          <div class="invalid-feedback">
            Please enter your login id /username
          </div>
        )}
      </div>
      <div className="form-group">
        {!hide?.includes("forgot-password") && (
          <Link to="/forgot-password" className="text-muted float-right">
            Forgot your password?
          </Link>
        )}
        <label htmlFor="password">Password</label>
        <input
          className="form-control"
          type="password"
          id="password"
          placeholder="Enter your password"
          {...register("password", { required: true })}
        />
        {errors.login && (
          <div class="invalid-feedback">Please enter your password</div>
        )}
      </div>
      <div className="mb-3 text-center">
        {/* <button className="btn btn-primary btn-block" type="submit">
          {buttonText}
        </button> */}
        <Link
          to={"/admin/dashboard"}
          className="btn btn-primary btn-block"
          type="submit"
        >
          Sign In
        </Link>
      </div>
    </form>
  );
}
