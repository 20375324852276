import React from "react";
import { BreadcrumbItem, PageTitle } from "../../../components/PageTitle";
import AdminLayout from "../../../layouts/AdminLayout";

export default function AddCustomer() {
  return (
    <AdminLayout>
      <PageTitle title="Add Customer">
        <BreadcrumbItem title={"Dashboard"} link={"/admin/dashboard"} />
        <BreadcrumbItem title={"Customers"} link={"/admin/customers/list"} />
        <BreadcrumbItem title={"Add"} isActive />
      </PageTitle>
      <div className="row">
        <div className="col-md-12">
          <form></form>
        </div>
      </div>
    </AdminLayout>
  );
}
