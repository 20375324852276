import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import { BreadcrumbItem, PageTitle } from "../../../components/PageTitle";

export default function AddServicePlan() {
  return (
    <AdminLayout>
      <PageTitle title="Add Service Plan">
        <BreadcrumbItem title={"Dashboard"} link={"/admin/dashboard"} />
        <BreadcrumbItem
          title={"Service Plans"}
          link={"/admin/service-plans/list"}
        />
        <BreadcrumbItem title={"Add"} isActive />
      </PageTitle>
      <div className="row">
        <div className="col-md-12">
          <form></form>
        </div>
      </div>
    </AdminLayout>
  );
}
