import React, { useEffect, useRef } from "react";
import $ from "jquery";
import DataTables from "datatables.net";

// Import necessary styles
import "datatables.net-dt/css/jquery.dataTables.min.css";

const Table = ({ tableId, dataSource, columns, ...props }) => {
  const tableRef = useRef();
  const dataTableRef = useRef(null);

  useEffect(() => {
    // When component loads, initialize or update DataTables

    if (!dataTableRef.current) {
      dataTableRef.current = new DataTables(`#${tableId}`, {
        buttons: ["copy", "print", "pdf"],
        language: {
          paginate: {
            previous: "<i class='mdi mdi-chevron-left'>",
            next: "<i class='mdi mdi-chevron-right'>",
          },
        },
        drawCallback: function() {
          $(".dataTables_paginate > .pagination").addClass(
            "pagination-rounded"
          );
        },
        columnDefs: [
          {
            targets: 0,
            checkboxes: {
              selectAll: true,
            },
          },
        ],
        select: {
          style: "multi",
        },
        data: dataSource,
        columns: columns,
      });
    } else {
      // If DataTables instance exists, destroy and reinitialize
      dataTableRef.current.clear().rows.add(dataSource).draw(); // Update data
    }

    // Cleanup: Destroy DataTables instance when component unmounts
  }, [dataSource, columns, tableId]);

  // Create a reference for the table
  return <table ref={tableRef} {...props}></table>;
};

export default Table;
