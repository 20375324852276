import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import routes from "./routes/RouteConfig";
import AppRoutes from "./routes";

function App() {
  return (
    <BrowserRouter basename="/">
      <AppRoutes routes={routes} />
    </BrowserRouter>
  );
}

export default App;
