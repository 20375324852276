import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReducer";

const rootReducer = combineReducers({
  authReducer,
});
const persistConfig = {
  key: "umbrella",
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer, // Pass the persisted reducer to configureStore
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export const initializeStore = (initialState = {}) => {
  return store;
};

export const persistor = persistStore(store);

export default store; // Export the store
