import React from "react";
import { PageTitle, BreadcrumbItem } from "../../components/PageTitle";
import AdminLayout from "../../layouts/AdminLayout";
import Statistic from "../../components/card/Statistic";
import { Link } from "react-router-dom";
import { ComboCard, ComboCardDropdown } from "../../components/card/ComboCard";

export default function AdminDashboard() {
  return (
    <AdminLayout>
      <PageTitle title="Dashboard">
        <BreadcrumbItem title={"Dashboard"} isActive />
      </PageTitle>
      {/* statistics */}
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <Link to="/admin/customers/list">
            <Statistic
              icon={"bx bx-user"}
              title={"Customers"}
              counter={3500}
              prefix=""
              other={{ title: "New this Month", counter: 300 }}
            />
          </Link>
        </div>
        <div className="col-xl-3 col-md-6">
          <Link to="/admin/transactions/list">
            <Statistic
              icon={"bx bx-dollar-circle"}
              title={"Revenues"}
              counter={3500500}
              prefix="Ksh"
              other={{ title: "Last Month", counter: +26, prefix: "%" }}
            />
          </Link>
        </div>
        <div className="col-xl-3 col-md-6">
          <Link to="/admin/tickets/list">
            <Statistic
              icon={"bx bx-support"}
              title={"Active Tickets"}
              counter={50}
              prefix=""
              other={{ title: "Pending", counter: 30, prefix: "" }}
            />
          </Link>
        </div>
        <div className="col-xl-3 col-md-6">
          <Link to="/admin/invoices/list?filter&status=pending">
            <Statistic
              icon={"bx bx-money"}
              title={"Pending Invoices"}
              counter={60}
              prefix=""
              other={{ title: "Due Today", counter: 30, prefix: "" }}
            />
          </Link>
        </div>
      </div>
      {/* end statistics */}

      {/* charts */}
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <ComboCard
            title={"Customers by Location"}
            withStats
            stats={[
              { title: "Total", counter: 360 },
              { title: "Online", counter: 288 },
            ]}
          >
            <ComboCardDropdown
              links={[
                { title: "Jamhuri", url: "#" },
                { title: "Tassia", url: "#" },
                { title: "Umoja", url: "#" },
                { title: "Fedha", url: "#" },
                { title: "etc", url: "#" },
              ]}
            />
            <div
              id="morris-donut-example"
              className="morris-chart"
              style={{ height: "260px" }}
            ></div>
          </ComboCard>
        </div>

        <div className="col-md-6 col-lg-4">
          <ComboCard
            title={"Total Revenue"}
            withStats
            stats={[
              { title: "Revenue", counter: 4000000 },
              { title: "Total Offers", counter: 288 },
            ]}
          >
            <ComboCardDropdown
              links={[
                { title: "All Time", url: "#" },
                { title: "This Month", url: "#" },
                { title: "Last Month", url: "#" },
                { title: "last 6 Months", url: "#" },
                { title: "This Year", url: "#" },
                { title: "Last Year", url: "#" },
              ]}
            />
            <div
              id="morris-bar-example"
              className="morris-chart"
              style={{ height: "260px" }}
            ></div>
          </ComboCard>
        </div>

        <div className="col-md-12 col-lg-4">
          <ComboCard title={"Network Signal Health"}>
            <ComboCardDropdown
              links={[
                { title: "All Locations", url: "#" },
                { title: "Jamhuri", url: "#" },
                { title: "Tassia", url: "#" },
                { title: "Umoja", url: "#" },
                { title: "Fedha", url: "#" },
                { title: "etc", url: "#" },
              ]}
            />
            <div
              id="morris-line-example"
              className="morris-chart"
              style={{ height: "230px" }}
            ></div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Region</th>
                    <th>Good</th>
                    <th>Low</th>
                    <th>Critical</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>All</td>
                    <td>2500</td>
                    <td>120</td>
                    <td>88</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ComboCard>
        </div>
      </div>
    </AdminLayout>
  );
}
