import React from "react";
import { PageTitle, BreadcrumbItem } from "../../../components/PageTitle";
import AdminLayout from "../../../layouts/AdminLayout";
import Table from "../../../components/table/Table";
import { Link } from "react-router-dom";

export default function CustomerList() {
  const columns = [
    {
      data: null,
      sortable: false,
      orderable: false,
      render: (_, type, row) => {
        if (type === "display") {
          return `<input type="checkbox" name="selected[]" value="${row.id}" id="customCheck1" />`;
        }
        return _;
      },
    },
    { title: "ID", data: "id" },
    { title: "Name", data: "name" },
    { title: "Email", data: "email" },
    { title: "Phone", data: "phone" },
    { title: "Service Plan", data: "servicePlan" },
    { title: "Status", data: "status" },
    { title: "Due Date", data: "dueDate" },
    {
      title: "Action",
      data: "id",
      render: (id, type, row) => {
        if (type === "display") {
          return `<div>
              <a href="/admin/customers/list/${id}"><i class="bx bx-file"></i></a>
              <a href="/admin/customers/edit/${id}"><i class="bx bx-edit text-success"></i></a>
              <a href="/admin/customers/trash/${id}"><i class="bx bx-trash text-danger"></i></a>
            </div>`;
        }
        return id;
      },
    },
  ];
  const data = [
    {
      id: "100",
      name: "Wachiye Siranjofu",
      email: "wachiye@gmail.com",
      phone: "0712345678",
      servicePlan: "Pico (8MBps)",
      status: "Active",
      dueDate: "15-08-2023",
    },
    {
      id: "101",
      name: "Jane Doe",
      email: "jane@example.com",
      phone: "0712345679",
      servicePlan: "Mega (50MBps)",
      status: "Active",
      dueDate: "18-08-2023",
    },
    {
      id: "102",
      name: "John Smith",
      email: "john@example.com",
      phone: "0712345680",
      servicePlan: "Giga (100MBps)",
      status: "Inactive",
      dueDate: "20-08-2023",
    },
    {
      id: "103",
      name: "Alice Johnson",
      email: "alice@example.com",
      phone: "0712345681",
      servicePlan: "Pico (8MBps)",
      status: "Active",
      dueDate: "22-08-2023",
    },
    {
      id: "104",
      name: "Robert Brown",
      email: "robert@example.com",
      phone: "0712345682",
      servicePlan: "Nano (2MBps)",
      status: "Active",
      dueDate: "25-08-2023",
    },
    {
      id: "105",
      name: "Linda Williams",
      email: "linda@example.com",
      phone: "0712345683",
      servicePlan: "Mega (50MBps)",
      status: "Inactive",
      dueDate: "28-08-2023",
    },
    {
      id: "106",
      name: "William Johnson",
      email: "william@example.com",
      phone: "0712345684",
      servicePlan: "Pico (8MBps)",
      status: "Active",
      dueDate: "30-08-2023",
    },
    {
      id: "107",
      name: "Mary Davis",
      email: "mary@example.com",
      phone: "0712345685",
      servicePlan: "Giga (100MBps)",
      status: "Active",
      dueDate: "02-09-2023",
    },
    {
      id: "108",
      name: "Michael Wilson",
      email: "michael@example.com",
      phone: "0712345686",
      servicePlan: "Nano (2MBps)",
      status: "Inactive",
      dueDate: "05-09-2023",
    },
    {
      id: "109",
      name: "Jennifer Lee",
      email: "jennifer@example.com",
      phone: "0712345687",
      servicePlan: "Mega (50MBps)",
      status: "Active",
      dueDate: "08-09-2023",
    },
  ];
  return (
    <AdminLayout>
      <PageTitle title="Customers">
        <BreadcrumbItem title={"Dashboard"} link={"/admin/dashboard"} />
        <BreadcrumbItem title={"Customers"} isActive />
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-5">
                Customer List
                <div className="float-right">
                  <div className="btn-toolbar" role="toolbar">
                    <div
                      className="btn-group mr-2"
                      role="group"
                      aria-label="First group"
                    >
                      <div className="btn-group" role="group">
                        <Link
                          to={"/admin/customers/add"}
                          className="btn btn-success waves-effect waves-light"
                        >
                          <i className="bx-bx-plus"></i>
                          Add Customer
                        </Link>
                      </div>

                      <div className="btn-group mx-2" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Bulk Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            willChange: "transform",
                            top: "0px",
                            left: "0px",
                            transform: "translate3d(0px, 35px, 0px)",
                          }}
                        >
                          <Link className="dropdown-item" to="#">
                            Extend Plan
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Send Mass Message
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Activate/Deactivate
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Block
                          </Link>
                        </div>
                      </div>

                      <div className="btn-group" role="group">
                        <button
                          id="btnGroupDrop2"
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Export <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop2"
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            willChange: "transform",
                            top: "0px",
                            left: "0px",
                            transform: "translate3d(0px, 35px, 0px)",
                          }}
                        >
                          <Link className="dropdown-item" to="#">
                            Excel
                          </Link>
                          <Link className="dropdown-item" to="#">
                            PDF
                          </Link>
                          <Link className="dropdown-item" to="#">
                            CSV
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h4>

              <Table
                columns={columns}
                dataSource={data}
                tableId="datatable-buttons"
                id="datatable-buttons"
                className="table table-striped dt-responsive nowrap"
              />
            </div>
            {/* <!-- end card body--> */}
          </div>
          {/* <!-- end card --> */}
        </div>
        {/* <!-- end col--> */}
      </div>
      {/* <!-- end row--> */}
    </AdminLayout>
  );
}
