import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  userRole: null,
  userDetails: {},
  authToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.userDetails = action.payload.userDetails;
      state.authToken = action.payload.authToken;
      state.userRole = action.payload.userRole;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.userRole = null;
      state.userDetails = {};
      state.authToken = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
