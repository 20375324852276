import React from "react";

import { Route, Routes } from "react-router-dom";

export default function AppRoutes({ routes = [] }) {
  return (
    <Routes>
      {routes.map((route) => {
        if (Array.isArray(route.path)) {
          return route.path.map((path) => (
            <Route key={path} path={path} element={route.component} />
          ));
        } else {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          );
        }
      })}
    </Routes>
  );
}
