import React from "react";

function Statistic({ title, counter, icon, surfix = "", prefix = "", other }) {
  return (
    <div className="card">
      <div className="card-body">
        {icon && <i className={`${icon} float-right m-0 h2 text-muted`}></i>}
        <h6 className="text-muted text-uppercase mt-0">{title}</h6>
        <h3 className="mb-3" data-plugin="counterup">
          <span className="small text-muted">{surfix}</span> {counter}{" "}
          <span className="small text-muted">{prefix}</span>
        </h3>
        {other && (
          <>
            <span className={`badge badge-success mr-1`}>
              <span className="small text-muted">{other.surfix}</span>{" "}
              {other.counter}{" "}
              <span className="small text-muted">{other.prefix}</span>
            </span>
            <span className="text-muted">{other.title}</span>
          </>
        )}
      </div>
    </div>
  );
}

export default Statistic;
