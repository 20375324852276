const API_URL = process.env.REACT_APP_API_URL;

const Api = {
  handleResponse: async function (response) {
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    if (data.status !== "success") {
      throw new Error(data.message || "Request failed");
    }

    return data;
  },
  request: async function (method, endpoint, data = {}, authToken = null) {
    const options = {
      method,
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };

    if (method !== "GET" && method !== "HEAD") {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(API_URL + endpoint, options);

    return this.handleResponse(response);
  },

  get: async function (endpoint, params, authToken = null) {
    const queryParams = new URLSearchParams(params);
    const fullUrl = queryParams
      ? `${endpoint}?${queryParams.toString()}`
      : endpoint;

    return await this.request("GET", fullUrl, authToken);
  },

  post: async function (endpoint, data, authToken = null) {
    return await this.request("POST", endpoint, data, authToken);
  },

  put: async function (endpoint, data, authToken = null) {
    return await this.request("PUT", endpoint, data, authToken);
  },

  patch: async function (endpoint, data, authToken = null) {
    return await this.request("PATCH", endpoint, data, authToken);
  },

  delete: async function (endpoint, data, authToken = null) {
    return await this.request("DELETE", endpoint, data, authToken);
  },
};

export default Api;
