import React from "react";
import { Link } from "react-router-dom";
import MetisMenu from "@metismenu/react";

export default function AdminSidebar() {
  return (
    <div data-simplebar className="h-100">
      <div className="navbar-brand-box">
        <Link to="/" className="logo">
          Umbrella
        </Link>
      </div>

      {/* <!--- Sidemenu --> */}
      <div id="sidebar-menu">
        {/* <!-- Left Menu Start --> */}
        <React.Fragment>
          <MetisMenu className="list-unstyled" id="side-menu">
            <li>
              <Link to={"/admin/dashboard"} className="waves-effect">
                <i className="bx bx-home-smile"></i>
                <span className="badge badge-pill badge-primary float-right">
                  7
                </span>
                <span>Dashboard</span>
              </Link>
            </li>

            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-file"></i>
                <span>Customers</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/customers/add">Add Customer</Link>
                </li>
                <li>
                  <Link to="/admin/customers/list">List Customer</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-shopping-bag"></i>
                Service Plans
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/service-plans/add">Add Plan</Link>
                </li>
                <li>
                  <Link to="/admin/service-plans/list">List Plans</Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-calendar"></i>
                Offers
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">Add Offer</Link>
                </li>
                <li>
                  <Link to="#">List Offers</Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-wifi"></i>
                <span>Network</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" className="has-arrow waves-effect">
                    <span>Locations & Cabinets</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/network/locations">Locations</Link>
                    </li>
                    <li>
                      <Link to="/admin/network/cabinets">Cabinets</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#" className="has-arrow waves-effect">
                    <span>Routers</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/network/routers/add">Add Router</Link>
                    </li>
                    <li>
                      <Link to="/admin/network/routers/list">List Routers</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/admin/network/firewall-rules"
                    className="waves-effect"
                  >
                    Firewall Rules
                  </Link>
                </li>
                <li>
                  <Link to="/admin/network/usage" className="waves-effect">
                    Network Usage
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-money"></i>
                <span>Billing & Invoices</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" className="has-arrow">
                    Invoices
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/billing/invoices/instant-invoice">
                        Instant Invoice
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/billing/invoices/pending">
                        Pending Invoices
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/billing/invoices/list">
                        List Invoices
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/billing/invoices/templates">
                        Invoice Templates
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/admin/billing/cycles">Billing Cycles</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-file"></i>
                <span>Payments</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/payment/accounts">Payment Accounts</Link>
                </li>
                <li>
                  <Link to="/admin/payment/transactions">
                    List Transactions
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-bell"></i>
                <span>Notifications</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/notifications/mass-messaging">
                    Mass Messaging
                  </Link>
                </li>
                <li>
                  <Link to="/admin/notifications/schedulers">Schedulers</Link>
                </li>
                <li>
                  <Link to="/admin/notifications/templates">Templates</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-support"></i>
                <span>Support</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" className="has-arrow waves-effect">
                    Leads
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/support/leads/add">Add Lead</Link>
                    </li>
                    <li>
                      <Link to="/admin/support/leads/pending">
                        Pending Leads
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/support/leads/list">List Leads</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#" className="has-arrow waves-effect">
                    Tickets
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin/support/tickets/add">Add Ticket</Link>
                    </li>
                    <li>
                      <Link to="/admin/support/tickets/pending">
                        Pending Tickets
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/support/tickets/list">List Tickets</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="menu-title">Admin</li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-chart"></i>
                <span>Reports & Analytics</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">Revenue Reports</Link>
                </li>
                <li>
                  <Link to="#">Invoice Reports</Link>
                </li>
                <li>
                  <Link to="#">Network Reports</Link>
                </li>
                <li>
                  <Link to="#">Ticket Reports</Link>
                </li>
                <li>
                  <Link to="#">System Performance</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-user"></i>
                <span>Manage Users</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/users/add">Add Admin</Link>
                </li>
                <li>
                  <Link to="/admin/users/list">List Admin</Link>
                </li>
                <li>
                  <Link to="/admin/users/roles">Roles & Permissions</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="bx bx-cog"></i>
                <span>Configurations</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/config/company-info">Company Info</Link>
                </li>
                <li>
                  <Link to="/admin/config/mikrotik">Mikrotik</Link>
                </li>
                <li>
                  <Link to="/admin/config/alerts">Alerts & Reminders</Link>
                </li>
                <li>
                  <Link to="/admin/config/security">System Security</Link>
                </li>
              </ul>
            </li>
          </MetisMenu>
        </React.Fragment>
      </div>
      {/* <!-- Sidebar --> */}
    </div>
  );
}
