import { Link } from "react-router-dom";
import React from "react";

export function BreadcrumbItem({ title, link, isActive = false }) {
  return (
    <li className={`breadcrumb-item ${isActive ? "active" : ""}`}>
      {link ? <Link to={link}>{title}</Link> : title}
    </li>
  );
}

export function PageTitle({ title = "Umbrella", children }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{title}</h4>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">{children}</ol>
          </div>
        </div>
      </div>
    </div>
  );
}
