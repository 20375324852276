import React from "react";

import { Link } from "react-router-dom";
import AdminLayout from "../../../../layouts/AdminLayout";
import { BreadcrumbItem, PageTitle } from "../../../../components/PageTitle";
import Table from "../../../../components/table/Table";

export default function CabinetList() {
  const onClickEdit = (e) => (row) => {
    e.preventDefault();

    console.log(row);
  };
  const columns = [
    {
      data: null,
      sortable: false,
      orderable: false,
      render: (_, type, row) => {
        if (type === "display") {
          return `<input type="checkbox" name="selected[]" value="${row.id}" id="customCheck1" />`;
        }
        return _;
      },
    },
    { title: "ID", data: "id" },
    { title: "Name", data: "name" },
    { title: "Location", data: "location" },
    { title: "Coordinates", data: "coordinates" },
    { title: "Customers", data: "customers" },
    { title: "Status", data: "status" },
    {
      title: "Action",
      data: "id",
      render: (id, type, row) => {
        if (type === "display") {
          return (
            <div>
              <a href="/admin/customers/list/${id}">
                <i class="bx bx-file"></i>
              </a>
              <a
                href="/admin/customers/edit/${id}"
                onClick={(e) => onClickEdit(e)(row)}
              >
                <i class="bx bx-edit text-success"></i>
              </a>
              <a href="/admin/customers/trash/${id}">
                <i class="bx bx-trash text-danger"></i>
              </a>
            </div>
          );
        }
        return id;
      },
    },
  ];
  const data = [
    {
      id: "100",
      name: "Cabinet 1",
      location: "Jamhuri",
      coordinates: { lat: 0.0001, lng: 33.0 },
      customers: 300,
      status: "Active",
    },
  ];
  return (
    <AdminLayout>
      <PageTitle title="Cabinets">
        <BreadcrumbItem title={"Dashboard"} link={"/admin/dashboard"} />
        <BreadcrumbItem title={"Network"} link={"/admin/network"} />
        <BreadcrumbItem title={"Cabinets"} isActive />
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-5">
                Cabinet List
                <div className="float-right">
                  <div className="btn-toolbar" role="toolbar">
                    <div
                      className="btn-group mr-2"
                      role="group"
                      aria-label="First group"
                    >
                      <div className="btn-group" role="group">
                        <Link
                          to={"#"}
                          className="btn btn-success waves-effect waves-light"
                        >
                          <i className="bx-bx-plus"></i>
                          Add Cabinet
                        </Link>
                      </div>

                      <div className="btn-group mx-2" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Bulk Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            willChange: "transform",
                            top: "0px",
                            left: "0px",
                            transform: "translate3d(0px, 35px, 0px)",
                          }}
                        >
                          <Link className="dropdown-item" to="#">
                            Select/Unselect
                          </Link>
                          <div className="dropdown-divider"></div>
                          <Link className="dropdown-item" to="#">
                            Send Mass Message
                          </Link>
                          <div className="dropdown-divider"></div>
                          <Link className="dropdown-item" to="#">
                            Activate/Deactivate
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Block
                          </Link>
                        </div>
                      </div>

                      <div className="btn-group" role="group">
                        <button
                          id="btnGroupDrop2"
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Export <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop2"
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            willChange: "transform",
                            top: "0px",
                            left: "0px",
                            transform: "translate3d(0px, 35px, 0px)",
                          }}
                        >
                          <Link className="dropdown-item" to="#">
                            Excel
                          </Link>
                          <Link className="dropdown-item" to="#">
                            PDF
                          </Link>
                          <Link className="dropdown-item" to="#">
                            CSV
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </h4>

              <Table
                columns={columns}
                dataSource={data}
                tableId="datatable-locations"
                id="datatable-locations"
                className="table table-striped dt-responsive nowrap"
              />
            </div>
            {/* <!-- end card body--> */}
          </div>
          {/* <!-- end card --> */}
        </div>
        {/* <!-- end col--> */}
      </div>
      {/* <!-- end row--> */}
    </AdminLayout>
  );
}
