import React from "react";

import {
  ComboCard,
  ComboCardDropdown,
} from "../../../../components/card/ComboCard";
import AdminLayout from "../../../../layouts/AdminLayout";
import { BreadcrumbItem, PageTitle } from "../../../../components/PageTitle";

export default function Locations() {
  const locations = [
    {
      id: "100",
      name: "Jamhuri",
      mapUrl: "https://maps.google.com/",
      cabinets: 4,
      customers: 500,
    },
    {
      id: "101",
      name: "Tassia",
      mapUrl: "https://maps.google.com/",
      cabinets: 4,
      customers: 500,
    },
  ];
  return (
    <AdminLayout>
      <PageTitle title="Locations">
        <BreadcrumbItem title={"Dashboard"} link={"/admin/dashboard"} />
        <BreadcrumbItem title={"Networks"} link={"/admin/networks"} />
        <BreadcrumbItem title={"Locations"} isActive />
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <div className="mb-2">
            <button className="btn btn-sm btn-primary">Add Location</button>
          </div>
        </div>
      </div>
      <div className="row">
        {locations?.map((location) => (
          <div className="col-md-6 col-lg-4" key={location.id}>
            <ComboCard
              title={location.name}
              withStats
              stats={[
                {
                  title: "Customers",
                  counter: location.customers,
                },
                {
                  title: "Cabinets",
                  counter: location.cabinets,
                },
              ]}
            >
              <ComboCardDropdown
                links={[
                  {
                    title: "Mass Message",
                    url: "#",
                    hasDivider: true,
                  },
                  {
                    title: "Edit",
                    url: "#",
                  },
                  {
                    title: "Disable/Enable",
                    url: "#",
                  },
                  {
                    title: "Remove",
                    url: "#",
                  },
                ]}
              />
              <div id="world-map-markers" style={{ height: "140px" }}></div>
            </ComboCard>
          </div>
        ))}
      </div>

      <script src="/plugins/jvectormap/jquery-jvectormap-2.0.2.min.js"></script>
      <script src="/plugins/jvectormap/jquery-jvectormap-world-mill-en.js"></script>
      <script src="/plugins/jvectormap/gdp-data.js"></script>
      <script src="/plugins/jvectormap/jquery-jvectormap-us-aea-en.js"></script>
      <script src="/plugins/jvectormap/jquery-jvectormap-uk-mill-en.js"></script>
      <script src="/plugins/jvectormap/jquery-jvectormap-us-il-chicago-mill-en.js"></script>
    </AdminLayout>
  );
}
