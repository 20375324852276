import React, { useState } from "react";
import LoginForm from "../components/auth/LoginForm";
import Api from "../lib/api";

export default function LoginPage() {
  const [loading, setIsLoading] = useState(false);

  const login = async (data) => {
    setIsLoading(true);

    await Api.post("/auth/login", data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setIsLoading(false);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center min-vh-100">
              <div className="w-100 d-block my-5">
                <div className="row justify-content-center">
                  <div className="col-md-8 col-lg-5">
                    <div className="card">
                      <div className="card-body">
                        <div className="text-center mb-4 mt-3">
                          <a href="index.html">
                            <span>
                              {/* <img
                                src="assets/images/logo-dark.png"
                                alt=""
                                height="26"
                              /> */}
                              Umbrella
                            </span>
                          </a>
                        </div>
                        <LoginForm onLoginSubmit={login} isLoading={loading} />
                      </div>
                      {/* <!-- end card-body --> */}
                    </div>
                    {/* <!-- end card --> */}
                  </div>
                  {/* <!-- end col --> */}
                </div>
                {/* <!-- end row --> */}
              </div>
              {/* <!-- end .w-100 --> */}
            </div>
            {/* <!-- end .d-flex --> */}
          </div>
          {/* <!-- end col--> */}
        </div>
        {/* <!-- end row --> */}
      </div>
      {/* <!-- end container --> */}
    </div>
  );
}
