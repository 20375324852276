import React from "react";

import AddCustomer from "../pages/admin/customers/add";
import ServicePlans from "../pages/admin/service-plans/list";
import AddServicePlan from "../pages/admin/service-plans/add";
import NotFound from "../pages/not-found";
import Locations from "../pages/admin/network/locations/locations";
import CabinetList from "../pages/admin/network/locations/cabinets";

import AdminDashboard from "../pages/admin";
import CustomerList from "../pages/admin/customers/list";
import LoginPage from "../pages/login";

const routes = [
  {
    path: ["/", "/login"],
    roles: [],
    isAuthenticated: false,
    component: <LoginPage />,
  },
  {
    path: ["/admin", "/admin/dashboard"],
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <AdminDashboard />,
  },
  // service plans
  {
    path: ["/admin/service-plans", "/admin/service-plans/list"],
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <ServicePlans />,
  },
  {
    path: "/admin/service-plans/add",
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <AddServicePlan />,
  },
  {
    path: "/admin/service-plans/edit/:servicePlanId",
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <AddServicePlan />,
  },
  // customers
  {
    path: ["/admin/customers", "/admin/customers/list"],
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <CustomerList />,
  },
  {
    path: "/admin/customers/add",
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <AddCustomer />,
  },
  {
    path: "/admin/customers/edit/{customerId}",
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <AddCustomer />,
  },
  // location and cabinets
  {
    path: ["/admin/network/locations"],
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <Locations />,
  },
  {
    path: ["/admin/network/cabinets", "/admin/network/cabinets/list"],
    roles: ["admin", "agent"],
    isAuthenticated: true,
    component: <CabinetList />,
  },
  {
    path: ["/not-found", "*"],
    roles: [],
    isAuthenticated: false,
    component: <NotFound />,
  },
];

export default routes;
