import React from "react";
import { Link } from "react-router-dom";

export function ComboCardDropdown({ links = [] }) {
  return (
    <div className="dropdown float-right position-relative">
      <Link
        to="#"
        className="dropdown-toggle h4 text-muted"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="mdi mdi-dots-vertical"></i>
      </Link>
      <ul className="dropdown-menu dropdown-menu-right">
        {links?.map((link, index) => (
          <React.Fragment key={index}>
            <li>
              <Link
                to={link.url}
                onClick={link.onClick ? link.onClick : null}
                className="dropdown-item"
              >
                {link.title}
              </Link>
            </li>
            {link?.hasDivider && <li className="dropdown-divider"></li>}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
}

export function ComboCard({ title, children, withStats = false, stats = [] }) {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title d-inline-block">{title}</h4>
        {children}
        {withStats ? (
          <div className="row text-center mt-4">
            {stats?.map((stat, index) => (
              <div className="col" key={index}>
                <h4>{stat.counter}</h4>
                <p className="text-muted mb-0">{stat.title}</p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
